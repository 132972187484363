import axios from 'axios'
import message from '@/components/message'

export default class Http {
  _baseUrl
  requst
  constructor(baseUrl) {
    if (baseUrl) {
      this._baseUrl = baseUrl
    } else if (process.env.NODE_ENV === 'development') {
      this._baseUrl = '/api'
    } else {
      this._baseUrl = '/api'
    }
    this.requst = axios.create({
      baseURL: this._baseUrl,
      timeout: 15000,
      headers: {
        'content-type': 'application/json',
      }
    })
    this.requst.interceptors.request.use((config) => {
      // const token = localStorage.getItem('token')
      // if (token && config.headers) config.headers.Token = localStorage.getItem('token')
      config.headers.language = window.localStorage.getItem('language') || 'zh-CN'
      return config
    })
    this.requst.interceptors.response.use((res) => {
      if (!res.data.success) {
        message({
          message: res.data.returnMsg,
          type: 'error',
        })
        throw new Error(JSON.stringify(res.data))
      }
      return res
    }, (err) => {
      message({
        message: '网络出错，请稍后重试！',
        type: 'error',
      })
      throw err
    })
  }

  async _callApi({ url, method, params, data, headers }) {
    return new Promise((resolve, reject) => {
      this.requst({
        url: url,
        method: method,
        params: params,
        data: data,
        headers: headers
      }).then((res) => {
        resolve(res.data)
      }).catch((err) => {
        reject(err)
      })
    })
  }
}
